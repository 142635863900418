var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pa-4"},_vm._l((Math.ceil(Object.keys(_vm.graphs).length / 3)),function(i,index){return _c('v-row',{key:index},[_c('v-col',{},[(
            typeof _vm.graphs[index * 3].url != 'undefined' &&
            _vm.graphs[index * 3].url != ''
          )?_c('iframe',{staticStyle:{"overflow":"hidden","height":"100%","width":"100%"},attrs:{"src":_vm.graphs[index * 3].url + '&var-Host=' + _vm.name,"frameborder":"0","height":"100%","width":"100%"}}):_vm._e()]),_c('v-col',{},[(
            typeof _vm.graphs[index * 3 + 1] != 'undefined' &&
            typeof _vm.graphs[index * 3 + 1].url != 'undefined' &&
            _vm.graphs[index * 3 + 1].url != ''
          )?_c('iframe',{staticStyle:{"overflow":"hidden","height":"100%","width":"100%"},attrs:{"src":_vm.graphs[index * 3 + 1].url + '&var-Host=' + _vm.name,"frameborder":"0","height":"100%","width":"100%"}}):_vm._e()]),_c('v-col',{},[(
            typeof _vm.graphs[index * 3 + 2] != 'undefined' &&
            typeof _vm.graphs[index * 3 + 2].url != 'undefined' &&
            _vm.graphs[index * 3 + 2].url != ''
          )?_c('iframe',{staticStyle:{"overflow":"hidden","height":"100%","width":"100%"},attrs:{"src":_vm.graphs[index * 3 + 2].url + '&var-Host=' + _vm.name,"frameborder":"0","height":"100%","width":"100%"}}):_vm._e()])],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }