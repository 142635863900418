<template>
  <div>
    <div class="pa-4">
      <v-row
        v-for="(i, index) in Math.ceil(Object.keys(graphs).length / 3)"
        :key="index"
      >
        <v-col class="">
          <iframe
            v-if="
              typeof graphs[index * 3].url != 'undefined' &&
              graphs[index * 3].url != ''
            "
            :src="graphs[index * 3].url + '&var-Host=' + name"
            frameborder="0"
            style="overflow: hidden; height: 100%; width: 100%"
            height="100%"
            width="100%"
          >
          </iframe>
        </v-col>
        <v-col class="">
          <iframe
            v-if="
              typeof graphs[index * 3 + 1] != 'undefined' &&
              typeof graphs[index * 3 + 1].url != 'undefined' &&
              graphs[index * 3 + 1].url != ''
            "
            :src="graphs[index * 3 + 1].url + '&var-Host=' + name"
            frameborder="0"
            style="overflow: hidden; height: 100%; width: 100%"
            height="100%"
            width="100%"
          >
          </iframe>
        </v-col>
        <v-col class="">
          <iframe
            v-if="
              typeof graphs[index * 3 + 2] != 'undefined' &&
              typeof graphs[index * 3 + 2].url != 'undefined' &&
              graphs[index * 3 + 2].url != ''
            "
            :src="graphs[index * 3 + 2].url + '&var-Host=' + name"
            frameborder="0"
            style="overflow: hidden; height: 100%; width: 100%"
            height="100%"
            width="100%"
          >
          </iframe>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props: ["name"],
  data() {
    return {
      graphs: [
        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=18",
        },
        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=16",
        },

        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=2",
        },

        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=14",
        },

        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=6",
        },

        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=10",
        },

        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=8",
        },

        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=4",
        },
        {
          url:
            "https://degust.acctopus.com/statistic-explorer/d-solo/BH3Z9PLMk/explorer-system-statistic?orgId=1&refresh=30s&theme=light&panelId=20",
        },
      ],
    };
  },
};
</script>


