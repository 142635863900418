<template>
  <div>
    <a @click="showDialog">
      <v-list-item data-type="showStatistics">
        <v-list-item-title>
          <v-card class="pa-2 mt-3 elevation-0">
            <v-icon class="mr-2 mt-n1">mdi-trending-up </v-icon
            >{{ language.componentLabel.explorerStatistics }}
          </v-card>
        </v-list-item-title>
      </v-list-item>
    </a>
    <v-dialog
      v-model="dialogDateFormat"
      width="600"
      scrollable
      :fullscreen="maximized"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="headline-box-headline primary" primary-title dark>
            <v-toolbar-title>Statistics</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn icon dark @click="maximized = !maximized">
              <v-icon v-if="maximized">mdi-window-maximize</v-icon>
              <v-icon v-else>mdi-window-restore</v-icon>
            </v-btn>
            <v-btn icon dark @click="dialogDateFormat = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <graphanaExplorerStatistics :name="row.c_name"></graphanaExplorerStatistics>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <primaryButton
            label="OK"
            v-on:click="dialogDateFormat = false"
          ></primaryButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import graphanaExplorerStatistics from "@/components/legacy/graphanaExplorerStatistics.vue";

import primaryButton from "@/commonComponents/primaryButton.vue";

export default {
  components: { graphanaExplorerStatistics, primaryButton },
  props: ["currentTable", "selectedRows", "row"],
  data() {
    return { dialogDateFormat: false, maximized: true };
  },
  methods: {
    showDialog() {
      this.dialogDateFormat = true;
      this.$emit("close-menu");
    },
  },
};
</script>